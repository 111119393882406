<template>
<div class="grid-wrapper">
    <div class="left-column d-flex align-items-center justify-content-center" style="height: 100vh">
        <div class="forgot-password-wrapper">
            <h2>Zaboravili ste lozinku?</h2>
            <form @submit.prevent="sendForgotPasswordRequest">
                <div class="form-group">
                    <label>Unesite vas email</label>
                    <input v-model="email" class="form-control" type="text" placeholder="Unesi email">
                </div>
                <button type="submit" class="btn forgot-password-btn">Posalji reset kod</button>
            </form>
        </div>
    </div>
    <div class="second-column"></div>
</div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router'
export default {
    setup() {
        const router = useRouter();
        const email = ref("");

        const sendForgotPasswordRequest = () => {
            axios.post(`${process.env.VUE_APP_API_URL}/api/Accounts/forgot-password`,{email: email.value})
                .then(() => {
                    router.push('resetuj-lozinku');
                })
                .catch(() => {
                    // handle error
                })
        }

        return {
            email,
            sendForgotPasswordRequest
        }
    },
}
</script>


<style scoped lang="scss">
.grid-wrapper {
    @include media(">=tablet") {
      display: grid;
      grid-template-columns: 1fr 1fr;  
    } 

    @include media("<=tablet") {
      display: flex;
      align-items: center;
      justify-content: center; 
    } 
}

    .forgot-password-wrapper {
        width: 30vw;

        @include media("<=tablet") { 
            width: 80vw;
        }
    }

        h2 {
            color: color(blue);
            font-size: 28.42px;

            @include media("<=phone-large") { 
                font-size: $h4-size!important;
            }
        }

        .form-group {
            margin-top: 20px;
        }

        .forgot-password-btn {
            width: 200px;
            height: 40px;
            background: color(blue);
            color: white;
            float: right;
            margin-top: 20px;
        }

        .forgot-password-btn:hover {
            color: white;
        }

    .second-column {
        @include media(">=tablet") {
            background: url(../../../assets/images/login-background.png);
            background-position: center;
            background-repeat: no-repeat; 
            background-size: cover;
        } 

        @include media("<=tablet") {
            display: none;
        } 
    }
</style>